<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'
import RoomieChipSelector from '@lahaus-roomie/roomie/src/components/RoomieChipSelector/index.vue'
import type { FormChipSelectorComponentModule } from '@/components/BrandedV2/FormSearchFilters/ChipSelector/types'

defineProps<FormChipSelectorComponentModule.Props>()
const model = defineModel<FormChipSelectorComponentModule.Props['modelValue']>({ required: true })
const { rt } = useI18n()
const itemsSelected = ref<string>('')

const toggleItemSelected = (value: string): void => {
  if (itemsSelected.value === value) {
    itemsSelected.value = ''
    return
  }

  itemsSelected.value = value
}

watch(model, (newValue): void => {
  if (!newValue) itemsSelected.value = ''
})

watch(itemsSelected, (newValue): void => {
  model.value = newValue
}, { deep: true })

onMounted((): void => {
  if (model.value) itemsSelected.value = `${model.value}`
})

</script>

<script lang="ts">
export default {
  name: 'FormChipSelector'
}
</script>

<template>
  <div class="flex flex-row flex-wrap gap-8">
    <RoomieChipSelector
      v-for="(option, index) in options"
      :id="`${id}-${index}`"
      :key="`${id}-${index}`"
      :data-lh-id="`${id}-chip-${index}-clicked`"
      class="my-4"
      :is-selected="(itemsSelected === rt(option.value) as unknown as string)"
      @click="toggleItemSelected(rt(option.value) as unknown as string)">
      <template #content>
        <span class="text-16 whitespace-nowrap pointer-events-none">
          {{ rt(option.label) }}
        </span>
      </template>
    </RoomieChipSelector>
  </div>
</template>
