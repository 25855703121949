import { ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { deepCopy, isArray, isObject } from '@/utils/objects'
import { useSearchFiltersStore } from '@/stores/searchFiltersStore'
import { isBoolean, isNumber, isString } from '@/utils/validations/primitives'
import { EVENTS_NAME_LIST } from '@/components/BrandedV2/FormSearchFilters/enums'
import type { FormSearchComposableModule } from '@/composables/BrandedV2/types'

const useFormSearch = (emit?: FormSearchComposableModule.Emits['submit']): FormSearchComposableModule.UseFormSearchReturn => {
  const searchFiltersStore = useSearchFiltersStore()
  const { currentFilters: formFilters, selectedFilters } = storeToRefs(searchFiltersStore)
  const filterCount = ref<number>(0)

  const onSubmit = (): void => {
    if (emit) {
      formFilters.value = deepCopy(selectedFilters.value)
      emit(EVENTS_NAME_LIST.FILTERS_CHANGE, deepCopy(formFilters.value))
    }
  }

  const resetFilters = (): void => {
    selectedFilters.value = {
      businessHubs: [],
      clusters: [],
      neighborhoods: [],
      opportunityType: null,
      deliveryTerm: null,
      bedroomsNumber: null,
      price: { min: null, max: null },
      q: null,
      area: { min: null, max: null },
      ids: [],
      parkingLotsNumber: null,
      bathroomsNumber: null
    }
  }

  const sumSelectedFilters = (filters: FormSearchComposableModule.Filters): number => {
    return Object.keys(filters).reduce((count: number, key: string) => {
      const value: any = filters[key as keyof FormSearchComposableModule.Filters]

      if (
        isString(value) ||
        isNumber(value) ||
        isBoolean(value) ||
        (isObject(value) && Object.keys(value).some((item: string) => value[item])) ||
        isArray(value)
      ) {
        return count + 1
      }

      return count
    }, 0)
  }

  const updateFilterCount = (filters: FormSearchComposableModule.Filters): void => {
    filterCount.value = sumSelectedFilters(filters)
  }

  watch(
    selectedFilters,
    (newValue: FormSearchComposableModule.Filters): void => {
      filterCount.value = sumSelectedFilters(newValue)
    },
    { deep: true }
  )

  return {
    formFilters,
    currentFilters: selectedFilters,
    filterCount,
    onSubmit,
    resetFilters,
    updateFilterCount,
    updateFilters: searchFiltersStore.updateFilters
  }
}

export default useFormSearch
