<script setup lang="ts">
import PriceRange from '@/components/SearchFilters/ModalContent/PriceRange/index.vue'
import FormChipSelector from '@/components/BrandedV2/FormSearchFilters/ChipSelector/index.vue'
import FormAreaRange from '@/components/BrandedV2/FormSearchFilters/AreaRange/index.vue'
import useFormSearch from '@/composables/BrandedV2/use-form-search'
import type { FormSearchFiltersComponentModule } from '@/components/BrandedV2/FormSearchFilters/types'

defineProps<FormSearchFiltersComponentModule.Props>()

const { currentFilters } = useFormSearch()
const { t, tm } = useI18n()

const deliveryTermOptions: any[] = tm('deliveryTerm.options')
const bedroomsOptions: any[] = tm('bedrooms.options')
const bathroomsOptions: any[] = tm('bathrooms.options')
const parkingLotsOptions: any[] = tm('parkingLots.options')

</script>

<script lang="ts">
export default {
  name: 'FormSearchFilters'
}
</script>

<template>
  <div class="form-filters__body">
    <h4 class="form-filters__title-section">
      {{ t('priceRange.label') }}
    </h4>

    <PriceRange
      :id="`${screenName}-price-range`"
      v-model:min-price="currentFilters.price.min"
      v-model:max-price="currentFilters.price.max"
      :max-price-label="t('priceRange.max')"
      :min-price-label="t('priceRange.min')" />

    <hr class="border-gray2-600">

    <div class="my-40">
      <h4 class="form-filters__title-section">
        {{ t('deliveryTerm.label') }}
      </h4>

      <FormChipSelector
        :id="`${screenName}-modal-filters-delivery-term-chip`"
        v-model="currentFilters.deliveryTerm"
        :options="deliveryTermOptions"
        class="form-filters__delivery-term" />
    </div>

    <hr class="border-gray2-600">

    <div class="my-40">
      <h2 class="form-filters__title-section">
        {{ t('characteristics') }}
      </h2>

      <p class="form-filters__input-label">
        {{ t('bedrooms.label') }}
      </p>

      <FormChipSelector
        :id="`${screenName}-modal-filters-bedrooms-number-chip`"
        v-model="currentFilters.bedroomsNumber"
        :options="bedroomsOptions"
        class="form-filters__tags" />
    </div>

    <hr class="border-gray2-600">

    <div class="my-40">
      <p class="form-filters__input-label">
        {{ t('parkingLots.label') }}
      </p>

      <FormChipSelector
        :id="`${screenName}-modal-filters-parking-lot-number-chip`"
        v-model="currentFilters.parkingLotsNumber"
        :options="parkingLotsOptions"
        class="form-filters__tags" />
    </div>

    <hr class="border-gray2-600">

    <div class="my-40">
      <p class="form-filters__input-label">
        {{ t('bathrooms.label') }}
      </p>

      <FormChipSelector
        :id="`${screenName}-modal-filters-bathrooms-number-chip`"
        v-model="currentFilters.bathroomsNumber"
        :options="bathroomsOptions"
        class="form-filters__tags" />
    </div>

    <hr class="border-gray2-600">

    <div class="mt-40">
      <h2 class="form-filters__title-section">
        {{ t('areaRange.label') }}
      </h2>

      <FormAreaRange
        :id="`${screenName}-modal-filters-area-range-chip`"
        v-model:min-area="currentFilters.area.min"
        v-model:max-area="currentFilters.area.max" />
    </div>
  </div>
</template>

<style src="./_index.scss"></style>
<i18n src="./i18n.json"></i18n>
