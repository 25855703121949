export enum ESTIMATED_DELIVERY_TIMEFRAME {
  IMMEDIATE = 'immediate',
  THREE_TO_SIX_MONTHS = '3-to-6-months',
  SIX_TO_TWELVE_MONTHS = '6-to-12-months',
  TWELVE_TO_TWENTY_FOUR_MONTHS = '12-to-24-months',
  MORE_THAN_TWENTY_FOUR_MONTHS = 'more-than-24-months',
  ALL = ''
}

export enum EVENTS_NAME_LIST {
  FILTERS_CHANGE = 'filters-change'
}
