<script setup lang="ts">
import { onMounted } from 'vue'
import RoomieInput from '@lahaus-roomie/roomie/src/components/RoomieInput/index.vue'
import MixMaxRange from '@/components/App/MinMaxRange/index.vue'
import type { FormAreaRangeComponentModule } from '@/components/BrandedV2/FormSearchFilters/AreaRange/types'

defineProps<FormAreaRangeComponentModule.Props>()

const { t } = useI18n()

const defaultRangeValues = {
  min: 1,
  max: 2000,
  interval: 1,
  gap: 5
}

const minArea = defineModel<number | undefined>('minArea', { default: null })
const maxArea = defineModel<number | undefined>('maxArea', { default: null })

const handleMinMaxChange = (event: { minValue: number, maxValue: number }) => {
  minArea.value = event.minValue
  maxArea.value = event.maxValue
}

onMounted(() => {
  if (minArea.value || maxArea.value) return

  minArea.value = defaultRangeValues.min
  maxArea.value = defaultRangeValues.max
})

</script>

<script lang="ts">
export default {
  name: 'FormAreaRange'
}
</script>

<template>
  <ClientOnly>
    <div class="form-area-range pb-24">
      <div class="grid grid-cols-2 gap-12 mb-12">
        <RoomieInput
          :id="`${id}-min-area-input`"
          v-model="minArea"
          :data-lh-id="`${id}-min-area-input`"
          :label="t('minLabel')"
          pattern="^\d*$"
          autocomplete="off"
          :is-disabled="false" />

        <RoomieInput
          :id="`${id}-max-area-input`"
          v-model="maxArea"
          :data-lh-id="`${id}-max-area-input`"
          :label="t('maxLabel')"
          pattern="^\d*$"
          autocomplete="off"
          :is-disabled="false" />
      </div>

      <MixMaxRange
        :id="`${id}-range-slider`"
        :initial-min="minArea"
        :initial-max="maxArea"
        :min-range="defaultRangeValues.min"
        :max-range="defaultRangeValues.max"
        :interval="defaultRangeValues.interval"
        :gap="defaultRangeValues.gap"
        @update:model-value="handleMinMaxChange" />
    </div>
  </ClientOnly>
</template>

<i18n lang="json" src="./i18n.json" />
